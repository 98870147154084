import React from 'react';
import { createRoot } from 'react-dom/client';
import DevexHighOrderComponent from '../../src/components/react/devex_high_order_component/devex_high_order_component';
import LoginSocialMediaSection from '../../src/login_social_media_section/login_social_media_section';

const looseEmailRegex = /^.+@.+$/;

document.addEventListener('DOMContentLoaded', () => {
  // Allow users to toggle visibility of the password input
  const toggleVisibilityIcon = document.getElementById(
    'toggleVisibilityIcon'
  ) as HTMLButtonElement;

  const passwordInput = document.querySelector('input[name=\'password\']') as HTMLInputElement;

  toggleVisibilityIcon.addEventListener(
    'click',
    function togglePasswordVisibility(event) {
      const target = event.target as HTMLElement;
      if (passwordInput.getAttribute('type') === 'password') {
        target.classList.replace('icon-eye-slash', 'icon-eye');
        passwordInput.setAttribute('type', 'text');
      } else {
        target.classList.replace('icon-eye', 'icon-eye-slash');
        passwordInput.setAttribute('type', 'password');
      }
    }
  );

  const form = document.querySelector('form[name=\'login-form\']') as HTMLInputElement;
  const emailInput = document.querySelector('input[name=\'login\']') as HTMLInputElement;

  let isEmailValid = false;
  let isPasswordValid = false;
  let hasEmailBeenTouched = false;
  let hasPasswordBeenTouched = false;

  // Add validation to the email input
  // The email input must contain a username, an @ symbol and a domain
  emailInput.addEventListener(
    'blur',
    function evaluateEmailValidity(event) {
      hasEmailBeenTouched = true;
      const value = (event.target as HTMLInputElement).value;
      isEmailValid = looseEmailRegex.test(value);

      if (isEmailValid) {
        emailInput.classList.remove('is-invalid');
      } else {
        emailInput.classList.add('is-invalid');
      }
    },
    false
  );

  // Add validation to the password input. The password input cannot be spaces only.
  passwordInput.addEventListener(
    'blur',
    function evaluatePasswordValidity(event) {
      hasPasswordBeenTouched = true;
      const value = (event.target as HTMLInputElement).value;
      const trimmedInput = value.trim();
      isPasswordValid = !!trimmedInput.length;

      if (isPasswordValid) {
        passwordInput.classList.remove('is-invalid');
        toggleVisibilityIcon.classList.remove('d-none');
      } else {
        passwordInput.classList.add('is-invalid');
        toggleVisibilityIcon.classList.add('d-none');
      }
    },
    false
  );

  form.addEventListener('submit', function validateForm(event) {
    // Re-evaluate the validity of the inputs
    // The form can be prefilled with the username
    // Users can for example autofill inputs. There is no dedicated JS event
    // for that so we need to double-check the validity of the inputs on
    // form submission.
    const emailValue = (emailInput as HTMLInputElement).value;
    isEmailValid = looseEmailRegex.test(emailValue);

    const passwordValue = (passwordInput as HTMLInputElement).value;
    isPasswordValid = !!passwordValue.trim().length;

    // After submitting the login form, re-enable the auto-login behavior
    localStorage.removeItem('preventAutoLogin');

    // If at least one of the inputs is invalid, prevent the form submission
    if (!isEmailValid || !isPasswordValid) {
      if (!isEmailValid && !hasEmailBeenTouched) {
        emailInput.classList.add('is-invalid');
      }

      if (!isPasswordValid && !hasPasswordBeenTouched) {
        passwordInput.classList.add('is-invalid');
        toggleVisibilityIcon.classList.add('d-none');
      }

      event.preventDefault();
      return null;
    }
    // Rails proceeds with the form submission
  });

  // Mount the React app responsible for rendering the social media login buttons
  const container = document.getElementById('login-social-media-section');
  if (!container) return null;
  const authToken = container.getAttribute('data-auth-token') as string;
  const returnTo = container.getAttribute('data-return-to') as string;

  const root = createRoot(container);
  root.render(
    <DevexHighOrderComponent>
      <LoginSocialMediaSection authToken={authToken} returnTo={returnTo} />
    </DevexHighOrderComponent>
  );
});
